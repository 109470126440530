// パスワードの一致確認
export const checkPasswordConfirm = (event) => {
  const form = event.target;

  if ( form.password.value != form.passwordConfirmation.value ) {
    const parentContainer = document.getElementById('sign_up_err');
    const message = 'パスワードと確認用パスワードが一致しません';
    let container = document.createElement('div');
    container.classList.add('alert', 'alert-warning');
    container.innerHTML = message;

    parentContainer.appendChild(container);
  }
}
