import 'StyleSheets/users/application/index';
import { reloadLocation } from './index/reloadLocation';
import { insertErrorMessage } from './index/insertErrorMessage';
import { checkPasswordConfirm } from './index/checkPasswordConfirm';

document.addEventListener('turbolinks:before-visit', function () {
  // 非常に苦しい処理.
  // WebSocketを確立させるために、ajax(turbolinks)で遷移させたくない.
  $(document).off('ajax:success', '#user-login-form');
  $(document).off('ajax:error', '#user-login-form');
  $('form#user-create-form').off('submit');
  $(document).off('ajax:error', '#user-create-form');
});

document.addEventListener('turbolinks:load', function () {
  // 非常に苦しい処理.
  // WebSocketを確立させるために、ajax(turbolinks)で遷移させたくない.
  $(document).on('ajax:success', '#user-login-form', () => reloadLocation());
  $(document).on('ajax:error', '#user-login-form', (event) => insertErrorMessage(event));
  $('form#user-create-form').on('submit', (event) => checkPasswordConfirm(event));
  $(document).on('ajax:error', '#user-create-form', (event) => insertErrorMessage(event));
});
