export const insertErrorMessage = (event) => {
  const containerId = event.target.id;
  let parentContainer;
  let message;

  // 発火したformで処理を分ける.
  switch ( containerId ) {
    case 'user-login-form':
      parentContainer = document.getElementById('sign_in_err');
      message = event.detail[2].response;
      break;
    case 'user-create-form':
      parentContainer = document.getElementById('sign_up_err');
      const jsonMessage = JSON.parse(event.detail[2].response);
      message = jsonMessage.message[0];
      break;
    default:
      break;
  }

  if ( !parentContainer || !message ) return;

  let container = document.createElement('div');
  container.classList.add('alert', 'alert-warning');
  container.innerHTML = message;

  parentContainer.appendChild(container);
}
